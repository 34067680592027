import React, { useEffect, useMemo } from 'react';
import { AppButton, AppFile, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddTeam() {
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    if (isEdit) {
      api.team.get(id).then(async res => {
        setData(res.data);
        setLogo(res.data.logo);
        reset(res.data);
      });
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.team[isEdit ? 'update' : 'create'](submitData.name, logo, id)
      .then(async () => {
        navigate('/team/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">{t('_teams.addTeamTitle')}</div>
      <div className="text-lg text-gray-700 mb-8 text-center">{t('_teams.addTeamDescription')}</div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_teams.name')}
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_teams.name')}
        />
        <img src={logo} className="w-16 h-auto mb-4 mt-4" />
        <AppFile error="" label="Takım Logosu" onCompleted={file => setLogo(file.cdnUrl)} crop="auto" />
        <AppButton type="submit" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
