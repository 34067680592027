export const matchApi = api => ({
  create(
    league,
    homeTeam,
    awayTeam,
    name,
    matchDate,
    homeContent,
    awayContent,
    slug,
    isShowHome,
    title,
    description,
    cover,
    homeScore,
    awayScore,
    isShowSlider,
    isFeaturedMatch
  ) {
    return api.post('/match/create', {
      league,
      homeTeam,
      awayTeam,
      name,
      matchDate,
      homeContent,
      awayContent,
      slug,
      isShowHome,
      title,
      description,
      cover,
      homeScore,
      awayScore,
      isShowSlider,
      isFeaturedMatch
    });
  },
  list(page) {
    return api.get(`/match/list/${page}`);
  },
  get(id) {
    return api.get(`/match/${id}`);
  },
  update(
    league,
    homeTeam,
    awayTeam,
    name,
    matchDate,
    homeContent,
    awayContent,
    slug,
    isShowHome,
    title,
    description,
    cover,
    homeScore,
    awayScore,
    isShowSlider,
    isFeaturedMatch,
    id
  ) {
    return api.post(`/match/update/${id}`, {
      league,
      homeTeam,
      awayTeam,
      name,
      matchDate,
      homeContent,
      awayContent,
      slug,
      isShowHome,
      title,
      description,
      cover,
      homeScore,
      awayScore,
      isShowSlider,
      isFeaturedMatch
    });
  },
  delete(id) {
    return api.delete(`/match/delete/${id}`);
  },
  matchesByLeague(league) {
    return api.get(`/match/matches-by-league/${league}`);
  },
  updateOne(id, params) {
    return api.post(`/match/update/${id}`, params);
  }
});
