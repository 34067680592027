import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

const NotificationList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const titles = ['BİLDİRİM BAŞLIĞI', 'BİLDİRİM İÇERİĞİ', 'TARİH'];

  useEffect(() => {
    api.notification
      .list()
      .then(async res => {
        const resData = [];
        for (let i = 0; i < res.data.length; i++) {
          const resDataItem = {
            title: res.data[i].headings.tr,
            message: res.data[i].contents.tr,
            date: new Date(res.data[i].send_after * 1000).toLocaleString()
          };
          resData.push(resDataItem);
        }
        setData(resData);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton className="mb-5 ml-auto" isFullWidth={false} onClick={() => navigate('/notification/send')}>
        Yeni Bildirim Gönder
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={false}
        pageKey="notification"
        pageNumber={1}
        hideRows="__v,_id"
      />
    </main>
  );
};

export default NotificationList;
