import React from 'react';
import menu from 'constants/menu';
import { Link } from 'react-router-dom';
import { MeetingRoomOutlined } from '@mui/icons-material';
import { useAuth } from 'context/AuthContext';

const AppSidebar = ({ activePage }) => {
  const { setUser } = useAuth();

  const signOut = () => {
    setUser(false);
    localStorage.removeItem('user');
  };

  return (
    <div className="w-full flex flex-col items-center justify-between">
      <div className="flex items-center justify-center">
        <img src="/logo.png" alt="10Numara" width={96} height={96} />
      </div>
      <div className="flex flex-col w-full">
        <div className="text-sm mb-4 font-semibold text-gray-400">Menü</div>
        {menu.map((item, index) =>
          item.isVisible ? (
            <Link
              to={item.path}
              key={index}
              className={`text-gray-300 mb-2 hover:text-gray-100 flex items-center hover:bg-gray-800 px-3 py-2 rounded-md transition-all ${
                activePage.mainPath === item.mainPath
                  ? 'bg-gray-800 text-gray-100'
                  : ''
              }`}
            >
              <item.Icon
                fontSize="small"
                className={`${item.icon} mr-4 text-gray-300`}
              ></item.Icon>
              <span className="font-medium">{item.name}</span>
            </Link>
          ) : (
            <div key={index}></div>
          )
        )}
        <div
          className="text-gray-300 mb-2 hover:text-gray-100 flex items-center hover:bg-gray-800 px-3 py-2 rounded-md transition-all cursor-pointer"
          onClick={signOut}
        >
          <MeetingRoomOutlined
            className="mr-4 text-gray-300"
            fontSize="small"
          />
          <span className="font-medium">Çıkış Yap</span>
        </div>
      </div>
      <div className="flex text-sm text-gray-400">
        {new Date().getFullYear()} © Admin Panel
      </div>
    </div>
  );
};

export default AppSidebar;
