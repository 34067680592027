import React from 'react';
import { AuthLayout, DashboardLayout } from '../layouts';
import Home from './Home';
import Login from './Login';
import Page404 from './Page404';
import TeamList from './Teams/TeamList';
import AddTeam from './Teams/AddTeam';
import LeagueList from './Leagues/LeagueList';
import AddLeague from './Leagues/AddLeague';
import UpdateLeague from './Leagues/UpdateLeague';
import MatchList from './Matches/MatchList';
import AddMatch from './Matches/AddMatch';
import RefereeList from './Referees/RefereeList';
import AddReferee from './Referees/AddReferee';
import LeagueActions from './Leagues/LeagueActions';
import NotificationList from './Notification/NotificationList';
import SendNotification from './Notification/SendNotification';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'team',
        children: [
          {
            path: 'list',
            element: <TeamList />
          },
          {
            path: 'add',
            element: <AddTeam />
          }
        ]
      },
      {
        path: 'league',
        children: [
          {
            path: 'list',
            element: <LeagueList />
          },
          {
            path: 'add',
            element: <AddLeague />
          },
          {
            path: 'update',
            element: <UpdateLeague />
          },
          {
            path: 'actions',
            element: <LeagueActions />
          }
        ]
      },
      {
        path: 'match',
        children: [
          {
            path: 'list',
            element: <MatchList />
          },
          {
            path: 'add',
            element: <AddMatch />
          }
        ]
      },
      {
        path: 'referee',
        children: [
          {
            path: 'list',
            element: <RefereeList />
          },
          {
            path: 'add',
            element: <AddReferee />
          }
        ]
      },
      {
        path: 'notification',
        children: [
          {
            path: 'list',
            element: <NotificationList />
          },
          {
            path: 'send',
            element: <SendNotification />
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export default routes;
