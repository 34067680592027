import React, { useEffect } from 'react';
import { AppButton, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    const countryData = [];
    api.country.list().then(res => {
      res.data.map(country => {
        countryData.push({ value: country.name, label: country.name });
      });
      setCountries(countryData);
    });
  }, []);

  useEffect(() => {
    const country = watch('country');
    if (country) {
      api.league.leaguesByCountry(country.value).then(res => {
        const leaguesData = [];
        res.data.map(data => {
          leaguesData.push({
            value: data.league.id,
            label: data.league.name
          });
        });
        setLeagues(leaguesData);
      });
    } else {
      setLeagues([]);
    }
  }, [watch('country')]);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.league.create(submitData.league.value)
      .then(async () => {
        navigate('/league/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">Yeni Lig Ekle</div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Bu alandan yeni bir lig ekleyebilirsiniz. Ligi seçtiğiniz değerler otomatik olarak eklenecektir.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <AppSelect
            options={countries}
            label={t('_leagues.country')}
            name="country"
            error={errors.country}
            rules={{ required: true }}
            control={control}
            placeholder={t('_leagues.country')}
          />
          <AppSelect
            options={leagues}
            label={t('_leagues.league')}
            name="league"
            error={errors.league}
            rules={{ required: true }}
            control={control}
            placeholder={t('_leagues.league')}
            isDisabled={leagues.length === 0}
          />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
