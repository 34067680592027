import React from 'react';

const Home = () => {
  return (
    <div>
      <h2 className="mb-10 text-2xl font-semibold text-gray-900">Admin Kullanım Kılavuzu</h2>
      <h3 className="mb-1 text-lg font-medium text-gray-600">Yeni Lig Nasıl Eklenir?</h3>
      <p className="prose text-gray-600">
        Yeni lig eklemek için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada sağ üstte bulunan{' '}
        <i>Lig Ekle</i> butonuna tıklayın. Açılan sayfada lig bilgilerini girin ve <strong>Lig Ekle</strong> butonuna
        tıklayın. Lig eklenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Lig Bilgileri Nasıl Güncellenir?</h3>
      <p className="prose text-gray-600">
        Lig bilgilerini güncellemek için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada
        güncellemek istediğiniz ligin bulunduğu satırın sağında bulunan <strong>Düzenle</strong> butonuna tıklayın.
        Açılan sayfada lig bilgilerini güncelleyin ve <strong>Kaydet</strong> butonuna tıklayın. Lig bilgileri
        güncellenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Lig Nasıl Yayına Alınır?</h3>
      <p className="prose text-gray-600">
        Ligi yayına almak için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada yayına almak
        istediğiniz ligin bulunduğu satırın sağında bulunan <strong>Yayında</strong> butonuna tıklayın ve aktif edin.
        Lig datalarını güncellemeden ligi yayına almayınız.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Lig Nasıl Favorilere Alınır?</h3>
      <p className="prose text-gray-600">
        Ligi favorilere almak için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada favorilere
        almak istediğiniz ligin bulunduğu satırın sağında bulunan <strong>Favori</strong> butonuna tıklayın ve aktif
        edin.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Takımlar Nasıl Oluşturulur?</h3>
      <p className="prose text-gray-600">
        Takım oluşturmak için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada takım oluşturmak
        istediğiniz ligin bulunduğu satırın sağında bulunan <strong>Güncelle</strong> butonuna tıklayın. Açılan sayfada
        Takımlar başlığı altında bulunan <strong>Takımları Oluştur</strong> butonuna tıklayın ve bekleyin. Takımlar
        oluşturulacaktır. <b>Bu işlemin sadece lig oluşturulduktan sonra 1 kere yapılması yeterlidir.</b>
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Takımlar Nasıl Güncellenir?</h3>
      <p className="prose text-gray-600">
        Takım bilgilerini güncellemek için sol menüde bulunan Takımlar sekmesine tıklayın. Ardından açılan sayfada
        güncellemek istediğiniz takımın bulunduğu satırın sağında bulunan <strong>Düzenle</strong> butonuna tıklayın.
        Açılan sayfada takım bilgilerini güncelleyin ve <strong>Kaydet</strong> butonuna tıklayın. Takım bilgileri
        güncellenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Lig Datası Nasıl Güncellenir?</h3>
      <p className="prose text-gray-600">
        Lig datasını güncellemek için sol menüde bulunan Ligler sekmesine tıklayın. Ardından açılan sayfada güncellemek
        istediğiniz ligin bulunduğu satırın sağında bulunan <strong>Güncelle</strong> butonuna tıklayın. Açılan sayfada
        ilgili kategorilerde güncellemek istediğiniz veriler için <b>Çalıştır</b> butonuna tıklayın ve bekleyin. Veriler
        güncellenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Haftanın Öne Çıkan Maçları Nasıl Güncellenir?</h3>
      <p className="prose text-gray-600">
        Haftanın öne çıkan maçlarını güncellemek için sol menüde bulunan Maçlar sekmesine tıklayın. Ardından açılan
        sayfada güncellemek istediğiniz maçın bulunduğu satırın sağında bulunan butonları aktif ederek istediğiniz
        kategoride maçı öne çıkan maçlar arasında gösterebilirsiniz.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Hakem Nasıl Eklenir?</h3>
      <p className="prose text-gray-600">
        Hakem oluşturmak için sol menüde bulunan Hakemler sekmesine tıklayın. Ardından açılan sayfada sağ üstte bulunan
        <i>Hakem Ekle</i> butonuna tıklayın. Açılan sayfada hakem bilgilerini girin ve <strong>Kaydet</strong> butonuna
        tıklayın. Hakem eklenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Maça Nasıl Hakem Eklenir?</h3>
      <p className="prose text-gray-600">
        Maça hakem eklemek için sol menüde bulunan Hakemler sekmesine tıklayın. Ardından açılan sayfada maçın hakemini
        eklemek istediğiniz satırın sağında bulunan <strong>Düzenle</strong> butonuna tıklayın. Açılan sayfada hakem
        bilgilerini güncelleyin ve <strong>Kaydet</strong> butonuna tıklayın. Hakem bilgileri güncellenecektir ve maça
        hakem eklenmiş olacaktır.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Hakem Nasıl Güncellenir?</h3>
      <p className="prose text-gray-600">
        Hakem bilgilerini güncellemek için sol menüde bulunan Hakemler sekmesine tıklayın. Ardından açılan sayfada
        güncellemek istediğiniz hakemin bulunduğu satırın sağında bulunan <strong>Düzenle</strong> butonuna tıklayın.
        Açılan sayfada hakem bilgilerini güncelleyin ve <strong>Kaydet</strong> butonuna tıklayın. Hakem bilgileri
        güncellenecektir.
      </p>
      <h3 className="mb-1 mt-5 text-lg font-medium text-gray-600">Bildirim Nasıl Gönderilir?</h3>
      <p className="prose text-gray-600">
        Bildirim göndermek için sol menüde bulunan Bildirimler sekmesine tıklayın. Ardından açılan sayfada sağ üstte
        bulunan
        <i>Bildirim Ekle</i> butonuna tıklayın. Açılan sayfada bildirim bilgilerini girin ve <strong>Kaydet</strong>{' '}
        butonuna tıklayın. Bildirim gönderilecektir.{' '}
        <b>Bildirimi geri alamazsınız. O yüzden dikkatli bir şekilde gönderilmesi gerekmektedir.</b>
      </p>
    </div>
  );
};

export default Home;
