import { AppSelect, AppTable } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useForm } from 'react-hook-form';
import { t } from '../../locales';

const LeagueList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const titles = ['LİG', 'TARİH', 'MAÇ ADI'];
  const [leagues, setLeagues] = useState([]);
  const [formData, setFormData] = useState({});
  const onDelete = async id => {
    api.match.delete(id).then(() => {
      navigate(0);
    });
  };

  const {
    control,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return formData;
    }, [formData])
  });

  const onAction = (id, type) => {
    if (type === 'isGoalPotential' || type === 'isCardPotential' || type === 'isCornerPotential') {
      const rows = [...data];
      const getRowIndex = rows.findIndex(item => item._id === id);
      rows[getRowIndex][type] = !rows[getRowIndex][type];
      setData(rows);
      const params = {};
      params[type] = rows[getRowIndex][type];
      api.match.updateOne(id, params);
    }
  };

  useEffect(() => {
    api.league.list().then(res => {
      const leaguesData = [];
      res.data.map(data => {
        leaguesData.push({ value: data.leagueId, label: `${data.name} - ${data.country}` });
      });
      setLeagues(leaguesData);
      const leagueFormData = {
        league: leaguesData[0]
      };
      setFormData(leagueFormData);
      reset(leagueFormData);
    });
  }, []);

  useEffect(() => {
    const league = watch('league');
    if (!league) return;
    api.match
      .list(league.value)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, [watch('league')]);

  return (
    <main className="w-full">
      <div className="flex w-1/4 ml-auto flex-row items-center justify-center">
        <AppSelect
          options={leagues}
          label="Lig Seç"
          name="league"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.league')}
        />
      </div>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={false}
        pageKey="match"
        pageNumber={page}
        onDelete={onDelete}
        onAction={onAction}
        switchs={[
          {
            property: 'isGoalPotential',
            name: 'GOL POTANSİYELİ'
          },
          {
            property: 'isCardPotential',
            name: 'KART POTANSİYELİ'
          },
          {
            property: 'isCornerPotential',
            name: 'KORNER POTANSİYELİ'
          }
        ]}
        hideRows="__v,_id,createdAt,homeId,awayId,referee,round,updatedAt,fixtureId,leagueId,isGoalPotential,isCardPotential,isCornerPotential"
      />
    </main>
  );
};

export default LeagueList;
