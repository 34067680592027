import React, { useEffect, useMemo } from 'react';
import {
  AppButton,
  AppCheckbox,
  AppEditor,
  AppFile,
  AppInput,
  AppSelect,
  MaskInput
} from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from 'utils/slug';
import { datePattern, timePattern } from 'constants/patterns';
import { dateToString, timeToString } from 'utils/date';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [cover, setCover] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const teamsData = [];
      const leaguesData = [];
      await api.league.list(0).then(async res => {
        res.data.map(league => {
          leaguesData.push({ value: league._id, label: league.name });
        });
        setLeagues(leaguesData);
      });
      await api.team.list(0).then(async res => {
        res.data.map(team => {
          teamsData.push({ value: team._id, label: team.name });
        });
        setTeams(teamsData);
      });
      if (isEdit) {
        await api.match.get(id).then(async res => {
          const data = res.data;
          const editData = {
            awayContent: data.awayContent,
            homeContent: data.homeContent,
            awayTeam: teamsData.find(team => team.value === data.awayTeam),
            homeTeam: teamsData.find(team => team.value === data.homeTeam),
            name: data.name,
            isShowHome: data.isShowHome,
            league: leaguesData.find(league => league.value === data.league),
            matchDate: dateToString(data.matchDate),
            matchTime: timeToString(data.matchDate),
            title: data.title,
            description: data.description,
            homeScore: data.homeScore,
            awayScore: data.awayScore,
            isShowSlider: data.isShowSlider,
            isFeaturedMatch: data.isFeaturedMatch
          };
          setCover(data.cover);
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const getDate = submitData.matchDate.split('/').reverse().join('-');
    await api.match[isEdit ? 'update' : 'create'](
      submitData.league.value,
      submitData.homeTeam.value,
      submitData.awayTeam.value,
      submitData.name,
      new Date(`${getDate} ${submitData.matchTime}`),
      submitData.homeContent,
      submitData.awayContent,
      convertToSlug(
        `${submitData.name} ${submitData.homeTeam.label} ${submitData.awayTeam.label}`
      ),
      submitData.isShowHome,
      submitData.title,
      submitData.description,
      cover,
      submitData.homeScore,
      submitData.awayScore,
      submitData.isShowSlider,
      submitData.isFeaturedMatch,
      id
    )
      .then(async () => {
        navigate('/match/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Maç Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir maç ekleyebilir ve bu maçı web sayfasındaki liglerde
        gösterebilirsiniz.. Lütfen tüm alanları eksiksiz doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={leagues}
          label={t('_matches.league')}
          name="league"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.league')}
        />
        <AppSelect
          options={teams}
          label={t('_matches.homeTeam')}
          name="homeTeam"
          // @ts-ignore
          error={errors.homeTeam}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.homeTeam')}
        />
        <AppSelect
          options={teams}
          label={t('_matches.awayTeam')}
          name="awayTeam"
          // @ts-ignore
          error={errors.awayTeam}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.awayTeam')}
        />
        <AppInput
          label={t('_matches.name')}
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder={t('_matches.name')}
        />
        <MaskInput
          label={t('_matches.matchDate')}
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="matchDate"
          // @ts-ignore
          error={errors.matchDate}
          rules={{ required: true, pattern: datePattern }}
          register={register}
          placeholder={t('_matches.matchDate')}
        />
        <MaskInput
          label={t('_matches.matchTime')}
          mask="99:99"
          maskPlaceholder="HH:MM"
          name="matchTime"
          // @ts-ignore
          error={errors.matchTime}
          rules={{ required: true, pattern: timePattern }}
          register={register}
          placeholder={t('_matches.matchTime')}
        />
        <AppInput
          label={t('_matches.title')}
          name="title"
          // @ts-ignore
          error={errors.title}
          rules={{}}
          register={register}
          placeholder={t('_matches.title')}
        />
        <AppInput
          label={t('_matches.description')}
          name="description"
          // @ts-ignore
          error={errors.description}
          rules={{}}
          register={register}
          placeholder={t('_matches.description')}
        />
        {cover ? <img src={cover} className="w-full h-auto mb-5" /> : <></>}
        <AppFile
          error=""
          label="Maç Fotoğrafı"
          onCompleted={file => setCover(file.cdnUrl)}
          crop="auto"
        />
        <AppEditor
          label={t('_matches.homeContent')}
          name="homeContent"
          // @ts-ignore
          error={errors.homeContent}
          rules={{ required: true, minLength: 20 }}
          control={control}
          placeholder={t('_matches.homeContent')}
        />
        <AppEditor
          label={t('_matches.awayContent')}
          name="awayContent"
          // @ts-ignore
          error={errors.awayContent}
          rules={{ required: true, minLength: 20 }}
          control={control}
          placeholder={t('_matches.awayContent')}
        />
        <AppInput
          label={t('_matches.homeScore')}
          name="homeScore"
          // @ts-ignore
          error={errors.homeScore}
          rules={{}}
          register={register}
          placeholder={t('_matches.homeScore')}
        />
        <AppInput
          label={t('_matches.awayScore')}
          name="awayScore"
          // @ts-ignore
          error={errors.awayScore}
          rules={{}}
          register={register}
          placeholder={t('_matches.awayScore')}
        />
        <AppCheckbox
          label={t('_matches.isShowHome')}
          name="isShowHome"
          // @ts-ignore
          error={errors.isShowHome}
          rules={{}}
          register={register}
          placeholder={t('_matches.isShowHome')}
        />
        <AppCheckbox
          label={t('_matches.isShowSlider')}
          name="isShowSlider"
          // @ts-ignore
          error={errors.isShowSlider}
          rules={{}}
          register={register}
          placeholder={t('_matches.isShowSlider')}
        />
        <AppCheckbox
          label={t('_matches.isFeaturedMatch')}
          name="isFeaturedMatch"
          // @ts-ignore
          error={errors.isFeaturedMatch}
          rules={{}}
          register={register}
          placeholder={t('_matches.isFeaturedMatch')}
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
