import React, { useEffect, useState } from 'react';
import {
  BarChart,
  CalendarMonth,
  FormatListNumbered,
  Groups,
  NextPlan,
  PieChart,
  ShowChart
} from '@mui/icons-material';
import api from '../../services/api';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const LeagueList = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const name = searchParams.get('name');
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState({});

  const { handleSubmit } = useForm({
    defaultValues: {
      fixtureLength: 10
    }
  });

  const toggleLoading = stateName => {
    setLoading(oldLoading => {
      return { ...oldLoading, [stateName]: !oldLoading[stateName] };
    });
  };

  const onAction = async (actionName, params) => {
    toggleLoading(`${actionName}${params ? params : ''}`);
    await api.league
      .updateLeagueData(actionName, id, params)
      .then(async () => {
        if (actionName === 'createTeams') {
          window.location.reload();
        } else {
          toggleLoading(`${actionName}${params ? params : ''}`);
        }
      })
      .catch(error => console.log('error', error));
  };

  const UpdateStatus = ({ statusName }) => {
    const status = loading[statusName];
    return (
      <span
        className={`
          inline-flex
          items-center
          justify-center
          px-3 py-1 ml-3 text-xs font-medium text-gray-100 rounded cursor-pointer
          ${status === undefined ? 'bg-gray-700' : status === false ? 'bg-green-600' : 'bg-blue-600'}
        }`}
      >
        {status === undefined ? 'Çalıştır' : status === false ? 'Tamamlandı' : 'Çalıştırılıyor'}
      </span>
    );
  };

  useEffect(() => {
    const init = async () => {
      api.league.getTeams(id).then(res => {
        setTeams(res.data);
      });
    };

    init();
  }, []);

  return (
    <>
      <h2 className="mb-4 text-3xl font-bold text-gray-600">{name}</h2>
      <main className="w-full grid grid-cols-2 gap-4">
        <div className="w-full p-4 border rounded-lg shadow sm:p-6 border-gray-700">
          <h5 className="mb-3 text-base font-semibold md:text-xl text-gray-700">Fikstür</h5>
          <p className="text-sm font-normal text-gray-500">
            Fikstürle ilgili işlemleri kapsar. Bağlı dataları otomatik olarak günceller.
          </p>
          <ul className="my-4 space-y-3">
            <li className="rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition p-3">
              <button
                onClick={handleSubmit(() => onAction('updateFixture'))}
                disabled={loading.updateFixture}
                className="flex w-full items-center justify-between text-base font-medium"
              >
                <div>
                  <CalendarMonth />
                  <span className="flex-1 ml-3 ">Fikstürü Güncelle</span>
                </div>
                <UpdateStatus statusName={`updateFixture`} />
              </button>
            </li>
            <li>
              <button
                onClick={() => onAction('updateTeamsMatches')}
                disabled={loading.updateTeamsMatches}
                className="flex w-full items-center justify-between text-base p-3 font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
              >
                <div>
                  <NextPlan />
                  <span className="flex-1 ml-3 ">Takım Maçlarını Güncelle</span>
                </div>
                <UpdateStatus statusName={'updateTeamsMatches'} />
              </button>
            </li>
            <li>
              <button
                onClick={() => onAction('resetPotentialMatches')}
                disabled={loading.resetPotentialMatches}
                className="flex w-full items-center justify-between text-base p-3 font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
              >
                <div>
                  <NextPlan />
                  <span className="flex-1 ml-3 ">Potansiyel Maçları Sıfırla (Tüm Ligler)</span>
                </div>
                <UpdateStatus statusName={'resetPotentialMatches'} />
              </button>
            </li>
          </ul>
        </div>
        <div className="w-full p-4 border rounded-lg shadow sm:p-6 border-gray-700">
          <h5 className="mb-3 text-base font-semibold md:text-xl text-gray-700">Puan Durumu</h5>
          <p className="text-sm font-normal text-gray-500">Ligdeki puan durumuyla ilgili işlemleri kapsar.</p>
          <ul className="my-4 space-y-3">
            <li>
              <button
                onClick={() => onAction('updateLeagueStanding')}
                disabled={loading.updateLeagueStanding}
                className="flex w-full items-center justify-between p-3 text-base font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
              >
                <div>
                  <FormatListNumbered />
                  <span className="flex-1 ml-3 ">Puan Durumunu Güncelle</span>
                </div>
                <UpdateStatus statusName={'updateLeagueStanding'} />
              </button>
            </li>
            <li>
              <button
                onClick={() => onAction('updateTeamsForm')}
                disabled={loading.updateTeamsForm}
                className="flex w-full items-center justify-between p-3 text-base font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
              >
                <div>
                  <ShowChart />
                  <span className="flex-1 ml-3 ">Takımların Formlarını Güncelle</span>
                </div>
                <UpdateStatus statusName={'updateTeamsForm'} />
              </button>
            </li>
            <li>
              <button
                onClick={() => onAction('createTeams')}
                disabled={loading.createTeams}
                className="flex w-full items-center justify-between p-3 text-base font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
              >
                <div>
                  <Groups />
                  <span className="flex-1 ml-3 ">Takımları Oluştur</span>
                </div>
                <UpdateStatus statusName={'createTeams'} />
              </button>
            </li>
          </ul>
        </div>
      </main>
      <h2 className="mt-8 mb-4 text-3xl font-bold text-gray-600">Takımlar</h2>
      <main className="w-full grid grid-cols-3 gap-4">
        {teams.map(team => (
          <div key={team._id} className="w-full p-4 mt-4 border rounded-lg shadow sm:p-6 border-gray-700">
            <h5 className="mb-3 text-base font-semibold md:text-xl text-gray-700">{team.name}</h5>
            <ul className="my-4 space-y-3">
              <li>
                <button
                  onClick={() => onAction('updateTeamsMatchesStatistics', team._id)}
                  disabled={loading.updateTeamsMatchesStatistics}
                  className="flex w-full items-center justify-between p-3 text-base font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
                >
                  <div>
                    <BarChart />
                    <span className="flex-1 ml-3 ">Takımın Son 20 Maçını Güncelle</span>
                  </div>
                  <UpdateStatus statusName={`updateTeamsMatchesStatistics${team._id}`} />
                </button>
              </li>
              <li>
                <button
                  onClick={() => onAction('updateTeamsSeasonStatistics', team._id)}
                  disabled={loading.updateTeamsSeasonStatistics}
                  className="flex w-full items-center justify-between p-3 text-base font-medium rounded-lg group hover:shadow-md bg-white border-2 border-gray-200 text-gray-500 transition"
                >
                  <div>
                    <PieChart />
                    <span className="flex-1 ml-3 ">Takımın Sezon Maçlarını Güncelle</span>
                  </div>
                  <UpdateStatus statusName={`updateTeamsSeasonStatistics${team._id}`} />
                </button>
              </li>
            </ul>
          </div>
        ))}
      </main>
    </>
  );
};

export default LeagueList;
