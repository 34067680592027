import React from 'react';
import { AppButton, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

export default function SendNotification() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({});

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.notification
      .send(submitData.title, submitData.message)
      .then(async () => {
        navigate('/notification/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        {t('_notification.sendNotificationTitle')}
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">{t('_notification.sendNotificationDescription')}</div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_notification.title')}
          name="title"
          // @ts-ignore
          error={errors.title}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_notification.title')}
        />
        <AppInput
          label={t('_notification.message')}
          name="message"
          // @ts-ignore
          error={errors.message}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_notification.message')}
        />
        <AppButton type="submit" isLoading={isLoading}>
          {t('_general.send')}
        </AppButton>
      </form>
    </main>
  );
}
