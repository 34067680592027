import { AppButton, AppSelect, AppTable } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { t } from '../../locales';
import { useForm } from 'react-hook-form';

const LeagueList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const titles = ['LİG ADI', 'HAKEM ADI', 'HAKEM FOTOĞRAFI'];
  const [leagues, setLeagues] = useState([]);
  const [formData, setFormData] = useState({});

  const {
    control,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return formData;
    }, [formData])
  });

  useEffect(() => {
    api.league.list().then(res => {
      const leaguesData = [];
      res.data.map(data => {
        leaguesData.push({ value: data.leagueId, label: `${data.name} - ${data.country}` });
      });
      setLeagues(leaguesData);
      const leagueFormData = {
        league: leaguesData[0]
      };
      setFormData(leagueFormData);
      reset(leagueFormData);
    });
  }, []);
  const onDelete = async id => {
    api.referee.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    const league = watch('league');
    if (!league) return;
    api.referee
      .list(league.value)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, [watch('league')]);

  return (
    <main className="w-full">
      <div className={`flex flex-row items-center justify-end mb-5`}>
        <div className="flex w-1/4 ml-auto flex-row items-center justify-center">
          <AppSelect
            options={leagues}
            label="Lig Seç"
            name="league"
            error={errors.league}
            rules={{ required: true }}
            control={control}
            placeholder={t('_matches.league')}
          />
        </div>
        <AppButton className="ml-4 mt-2" isFullWidth={false} onClick={() => navigate('/referee/add')}>
          Yeni Hakem Ekle
        </AppButton>
      </div>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="referee"
        pageNumber={page}
        onDelete={onDelete}
        hideRows="__v,_id,slug,createdAt,updatedAt,matchNumber,matchAverageFoul,matchAverageCard,matchYellowCard,matchRedCard,matchPenalty,seasonAverageFoul,seasonAverageCard,seasonYellowCard,seasonRedCard,seasonPenalty,season,country,date,nextMatch,leagueId,seasonMatchNumber"
      />
    </main>
  );
};

export default LeagueList;
