import React from 'react';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AppInput, AppButton } from '../components';
import { t } from 'locales';
import { emailPattern } from '../constants/patterns';
import api from 'services/api';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.auth
      .login(submitData.email, submitData.password)
      .then(async response => {
        const user = response.data.user;
        setUser(user);
        navigate('/');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex justify-center mb-5">
        <img src="/logo-auth.png" width="142" />
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_auth.email')}
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder={t('_auth.email')}
        />
        <AppInput
          label={t('_auth.password')}
          name="password"
          error={errors.password}
          rules={{ required: true }}
          type="password"
          register={register}
          placeholder={t('_auth.password')}
        />
        <AppButton type="submit" isLoading={isLoading}>
          {t('_auth.login')}
        </AppButton>
      </form>
    </div>
  );
};

export default Login;
