export default {
  _formErrorMessages: {
    required: '{{label}} alanı zorunludur.',
    minLength: '{{label}} alanını eksiksiz girmelisin.',
    pattern: '{{label}} alanına lütfen geçerli bir değer giriniz.'
  },
  _auth: {
    login: 'Giriş Yap',
    register: 'Kayıt Ol',
    forgotPassword: 'Şifremi Unuttum',
    resetPassword: 'Şifremi Sıfırla',
    logout: 'Çıkış Yap',
    email: 'E-Posta',
    password: 'Şifre',
    enterEmail: 'E-Posta Adresinizi Giriniz',
    enterPassword: 'Şifrenizi Giriniz',
    loginDescription:
      'Lütfen aşağıdaki butona tıklayarak Google ile giriş yapınız. Bu işlem yalnızca onaylanmış kullanıcılar için geçerlidir.',
    loginWithGoogle: 'Google ile Giriş Yap',
    loginAccessDenied: 'Giriş yapmak için yetkiniz yok. Lütfen geçerli e-posta adresinizle giriş yapınız.'
  },
  _pageName: {
    home: 'Anasayfa',
    about: 'Hakkımızda',
    deploy: 'Yayınla',
    contact: 'İletişim',
    gallery: 'Galeri',
    slider: 'Slider',
    reference: 'Referanslar',
    project: 'Projeler'
  },
  _general: {
    image: 'Resim',
    save: 'Kaydet',
    send: 'Gönder'
  },
  _deployPage: {
    title: 'Yaptığınız değişiklikleri yayınlayın',
    subtitle:
      'Bu sayfada yaptığınız değişiklikleri yayına alabilirsiniz. Yayınlanan değişikliklerinizi lütfen gözden geçirdikten sonra yayına alınız.',
    time: 'Yayınlanma Zamanı',
    minute: 'dakika',
    status: 'Durum',
    statusPending: 'Yayına Alınıyor',
    statusSuccess: 'Başarılı',
    deploying: 'Yayınlanıyor',
    deploy: 'Yayınla'
  },
  _contactPage: {
    title: 'İletişim Bilgileriniz',
    subtitle: 'Lütfen iletişim bilgilerinizi ekleyiniz. Olmayan bir alanınız var ise, lütfen boş bırakınız.',
    phoneNumber: 'Telefon Numarası',
    email: 'E-Posta',
    address: 'İşyeri Adresi',
    footerText: 'Alt Alan İçeriği',
    facebookAccount: 'Facebook Hesabı',
    twitterAccount: 'Twitter Hesabı',

    instagramAccount: 'Instagram Hesabı',
    linkedinAccount: 'LinkedIn Hesabı',
    emailAccount: 'E-Posta Adresi',
    quotation: 'Alıntı',
    quotationHolder: 'Alıntı Sahibi'
  },
  _alerts: {
    successTitle: 'Başarılı',
    successSubtitle: 'Değişikler başarıyla kaydedildi.',
    infoTimeTitle: 'Dikkat',
    infoTimeSubtitle:
      'Bu işlem 2-3 dakikayı bulabilmektedir. Lütfen işlemin tamamlanmasını bekleyiniz ve sayfayı kapatmayınız.'
  },
  _teams: {
    addTeamTitle: 'Yeni Takım Ekle',
    addTeamDescription:
      'Yeni bir takım ekleyebilir ve bu takımı maçlarda seçebilirsiniz. Lütfen eklerken tüm alanları eksiksiz doldurunuz.',
    name: 'Takım İsmi',
    manager: 'Teknik Direktör'
  },
  _leagues: {
    country: 'Ülke',
    name: 'Lig İsmi',
    weekName: 'Hafta Başlığı',
    league: 'Lig',
    fixtureLength: 'Fikstür Uzunluğu (Maç Adedi)'
  },
  _matches: {
    league: 'Lig',
    homeTeam: 'Ev Sahibi Takım',
    awayTeam: 'Deplasman Takımı',
    matchDate: 'Maç Tarihi',
    name: 'Maç İsmi',
    matchTime: 'Maç Saati',
    homeContent: 'Ev Sahibi Açıklaması',
    awayContent: 'Deplasman Açıklaması',
    isShowHome: "Anasayfa'da gösterilsin mi?",
    title: 'Maç Başlığı',
    description: 'Maç Açıklaması',
    homeScore: 'Ev Sahibi Skoru',
    awayScore: 'Deplasman Skoru',
    isShowSlider: "Slider'a eklensin mi?",
    isFeaturedMatch: 'Bu öne çıkan maç mı?'
  },
  _referees: {
    league: 'Lig',
    name: 'Hakem İsmi',
    photo: 'Hakem Fotoğrafı',
    matchNumber: 'Maç Sayısı',
    nextMatch: 'Sonraki Yöneteceği Maç',
    country: 'Hakemin Ülkesi',
    latestMatchesInformation: 'Son Maç Bilgileri',
    matchAverageFoul: 'Ortalama Faul - Maç',
    matchAverageCard: 'Ortalama Kart - Maç',
    matchYellowCard: 'Sarı Kart - Maç',
    matchRedCard: 'Kırmızı Kart - Maç',
    matchPenalty: 'Penaltı - Maç',
    seasonInformation: 'Sezon Bilgileri',
    season: 'Sezon',
    seasonMatchNumber: 'Sezon Maç Sayısı',
    seasonAverageFoul: 'Ortalama Faul - Sezon',
    seasonAverageCard: 'Ortalama Kart - Sezon',
    seasonYellowCard: 'Sarı Kart - Sezon',
    seasonRedCard: 'Kırmızı Kart - Sezon',
    seasonPenalty: 'Penaltı - Sezon'
  },
  _notification: {
    title: 'Bildirim Başlığı',
    message: 'Bildirim İçeriği',
    sendNotificationTitle: 'Bildirim Gönder',
    sendNotificationDescription:
      'Lütfen aşağıdaki alanları eksiksiz doldurunuz. Bildirim gönderildikten sonra bildirim listesi sayfasına yönlendirileceksiniz. Bu işlem geri alınamaz. Lütfen göndermeden önce bildiriminizi gözden geçiriniz.'
  }
};
