export const teamApi = api => ({
  create(name, logo) {
    return api.post('/team/create', {
      name,
      logo
    });
  },
  list(page) {
    return api.get(`/team/list/${page}`);
  },
  get(id) {
    return api.get(`/team/${id}`);
  },
  update(name, logo, id) {
    return api.post(`/team/update/${id}`, {
      name,
      logo
    });
  },
  delete(id) {
    return api.delete(`/team/delete/${id}`);
  }
});
