import React from 'react';
import { FormErrorMessage } from 'components';
import { Controller } from 'react-hook-form';
import MDEditor from '@uiw/react-md-editor';

const AppEditor = ({ label, name, error, rules, control, placeholder }) => {
  return (
    <div className="w-full mb-5" data-color-mode="light">
      <label
        htmlFor={name}
        className={`w-full block mb-2 text-sm font-medium ${
          error ? 'text-red-600' : 'text-gray-900'
        }`}
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <MDEditor
            {...field}
            height={300}
            textareaProps={{
              placeholder
            }}
          />
        )}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppEditor;
