export const refereeApi = api => ({
  create(
    league,
    leagueId,
    name,
    photo,
    country,
    nextMatch,
    matchNumber,
    matchAverageFoul,
    matchAverageCard,
    matchYellowCard,
    matchRedCard,
    matchPenalty,
    season,
    seasonMatchNumber,
    seasonAverageFoul,
    seasonAverageCard,
    seasonYellowCard,
    seasonRedCard,
    seasonPenalty
  ) {
    return api.post('/referee/create', {
      league,
      leagueId,
      name,
      photo,
      country,
      nextMatch,
      matchNumber,
      matchAverageFoul,
      matchAverageCard,
      matchYellowCard,
      matchRedCard,
      matchPenalty,
      season,
      seasonMatchNumber,
      seasonAverageFoul,
      seasonAverageCard,
      seasonYellowCard,
      seasonRedCard,
      seasonPenalty
    });
  },
  list(page) {
    return api.get(`/referee/list/${page}`);
  },
  get(id) {
    return api.get(`/referee/${id}`);
  },
  update(
    league,
    leagueId,
    name,
    photo,
    country,
    nextMatch,
    matchNumber,
    matchAverageFoul,
    matchAverageCard,
    matchYellowCard,
    matchRedCard,
    matchPenalty,
    season,
    seasonMatchNumber,
    seasonAverageFoul,
    seasonAverageCard,
    seasonYellowCard,
    seasonRedCard,
    seasonPenalty,
    id
  ) {
    return api.post(`/referee/update/${id}`, {
      league,
      leagueId,
      name,
      photo,
      country,
      nextMatch,
      matchNumber,
      matchAverageFoul,
      matchAverageCard,
      matchYellowCard,
      matchRedCard,
      matchPenalty,
      season,
      seasonMatchNumber,
      seasonAverageFoul,
      seasonAverageCard,
      seasonYellowCard,
      seasonRedCard,
      seasonPenalty
    });
  },
  delete(id) {
    return api.delete(`/referee/delete/${id}`);
  }
});
