import React, { useEffect, useMemo } from 'react';
import { AppButton, AppFile, AppInput, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [leagues, setLeagues] = useState([]);
  const [photo, setPhoto] = useState();
  const [matches, setMatches] = useState([]);
  const [countries, setCountries] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const league = watch('league');
    if (league) {
      api.match.matchesByLeague(league.leagueId).then(res => {
        const matchesData = [
          {
            value: '',
            label: 'Henüz belli değil'
          }
        ];
        res.data.map(data => {
          matchesData.push({
            value: data.fixtureId,
            label: data.name
          });
        });
        setMatches(matchesData);
      });
    } else {
      setMatches([]);
    }
  }, [watch('league')]);

  useEffect(() => {
    const init = async () => {
      const leaguesData = [];
      await api.league.list(0).then(async res => {
        res.data.map(league => {
          leaguesData.push({ value: league._id, label: league.name, leagueId: league.leagueId });
        });
        setLeagues(leaguesData);
      });
      const countryData = [];
      await api.country.list().then(res => {
        res.data.map(country => {
          countryData.push({ value: country.code, label: country.name });
        });
        setCountries(countryData);
      });
      if (isEdit) {
        const editData = {};
        let league;
        await api.referee.get(id).then(async res => {
          const data = res.data;
          league = leaguesData.find(league => league.value === data.league);
          editData.league = league;
          editData.name = data.name;
          editData.country = countryData.find(country => country.value === data.country);
          editData.nextMatch = data.nextMatch;
          editData.matchNumber = data.matchNumber;
          editData.matchAverageFoul = data.matchAverageFoul;
          editData.matchAverageCard = data.matchAverageCard;
          editData.matchYellowCard = data.matchYellowCard;
          editData.matchRedCard = data.matchRedCard;
          editData.matchPenalty = data.matchPenalty;
          editData.season = data.season;
          editData.seasonMatchNumber = data.seasonMatchNumber;
          editData.seasonAverageFoul = data.seasonAverageFoul;
          editData.seasonAverageCard = data.seasonAverageCard;
          editData.seasonYellowCard = data.seasonYellowCard;
          editData.seasonRedCard = data.seasonRedCard;
          editData.seasonPenalty = data.seasonPenalty;
          setPhoto(data.photo);
        });
        await api.match.matchesByLeague(league.leagueId).then(res => {
          const matchesData = [
            {
              value: '',
              label: 'Henüz belli değil'
            }
          ];
          res.data.map(data => {
            matchesData.push({
              value: data.fixtureId,
              label: data.name
            });
          });
          editData.nextMatch = matchesData.find(match => match.value === editData.nextMatch);
          setMatches(matchesData);
        });
        setData(editData);
        reset(editData);
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.referee[isEdit ? 'update' : 'create'](
      submitData.league.value,
      submitData.league.leagueId,
      submitData.name,
      photo,
      submitData.country.value,
      submitData.nextMatch.value,
      submitData.matchNumber,
      submitData.matchAverageFoul,
      submitData.matchAverageCard,
      submitData.matchYellowCard,
      submitData.matchRedCard,
      submitData.matchPenalty,
      submitData.season,
      submitData.seasonMatchNumber,
      submitData.seasonAverageFoul,
      submitData.seasonAverageCard,
      submitData.seasonYellowCard,
      submitData.seasonRedCard,
      submitData.seasonPenalty,
      id
    )
      .then(async () => {
        navigate('/referee/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        {isEdit ? 'Hakem Düzenle' : 'Yeni Hakem Ekle'}
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir hakem ekleyebilir ve bu hakeme ait istatistikleri belirleyebilirsiniz. Eklediğiniz hakem yalnızca o lig
        için gözükecektir.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={leagues}
          label={t('_referees.league')}
          name="league"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder={t('_referees.league')}
        />
        <AppSelect
          options={matches}
          label={t('_referees.nextMatch')}
          name="nextMatch"
          error={errors.nextMatch}
          rules={{ required: true }}
          control={control}
          placeholder={t('_referees.nextMatch')}
          isDisabled={matches.length === 0}
        />
        <AppInput
          label={t('_referees.name')}
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder={t('_referees.name')}
        />
        {photo ? <img src={photo} className="w-full h-auto mb-5" /> : <></>}
        <AppFile error="" label="Hakem Fotoğrafı" onCompleted={file => setPhoto(file.cdnUrl)} crop="1:1" />
        <AppSelect
          options={countries}
          label={t('_referees.country')}
          name="country"
          error={errors.country}
          rules={{ required: true }}
          control={control}
          placeholder={t('_referees.country')}
        />
        <h2 className="font-bold mt-4 mb-2 text-gray-700 text-lg">{t('_referees.latestMatchesInformation')}</h2>
        <AppInput
          label={t('_referees.matchNumber')}
          name="matchNumber"
          // @ts-ignore
          error={errors.matchNumber}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchNumber')}
        />
        <AppInput
          label={t('_referees.matchAverageFoul')}
          name="matchAverageFoul"
          // @ts-ignore
          error={errors.matchAverageFoul}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchAverageFoul')}
        />
        <AppInput
          label={t('_referees.matchAverageCard')}
          name="matchAverageCard"
          // @ts-ignore
          error={errors.matchAverageCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchAverageCard')}
        />
        <AppInput
          label={t('_referees.matchYellowCard')}
          name="matchYellowCard"
          // @ts-ignore
          error={errors.matchYellowCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchYellowCard')}
        />
        <AppInput
          label={t('_referees.matchRedCard')}
          name="matchRedCard"
          // @ts-ignore
          error={errors.matchRedCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchRedCard')}
        />
        <AppInput
          label={t('_referees.matchPenalty')}
          name="matchPenalty"
          // @ts-ignore
          error={errors.matchPenalty}
          rules={{}}
          register={register}
          placeholder={t('_referees.matchPenalty')}
        />
        <h2 className="font-bold mt-4 mb-2 text-gray-700 text-lg">{t('_referees.seasonInformation')}</h2>
        <AppInput
          label={t('_referees.season')}
          name="season"
          // @ts-ignore
          error={errors.season}
          rules={{}}
          register={register}
          placeholder={t('_referees.season')}
        />
        <AppInput
          label={t('_referees.seasonMatchNumber')}
          name="seasonMatchNumber"
          // @ts-ignore
          error={errors.seasonMatchNumber}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonMatchNumber')}
        />
        <AppInput
          label={t('_referees.seasonAverageFoul')}
          name="seasonAverageFoul"
          // @ts-ignore
          error={errors.seasonAverageFoul}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonAverageFoul')}
        />
        <AppInput
          label={t('_referees.seasonAverageCard')}
          name="seasonAverageCard"
          // @ts-ignore
          error={errors.seasonAverageCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonAverageCard')}
        />
        <AppInput
          label={t('_referees.seasonYellowCard')}
          name="seasonYellowCard"
          // @ts-ignore
          error={errors.seasonYellowCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonYellowCard')}
        />
        <AppInput
          label={t('_referees.seasonRedCard')}
          name="seasonRedCard"
          // @ts-ignore
          error={errors.seasonRedCard}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonRedCard')}
        />
        <AppInput
          label={t('_referees.seasonPenalty')}
          name="seasonPenalty"
          // @ts-ignore
          error={errors.seasonPenalty}
          rules={{}}
          register={register}
          placeholder={t('_referees.seasonPenalty')}
        />

        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
