export const leagueApi = api => ({
  create(leagueId) {
    return api.post('/league/create', {
      leagueId
    });
  },
  list(page) {
    return api.get(`/league/list/${page}`);
  },
  get(id) {
    return api.get(`/league/${id}`);
  },
  update(name, logo, flag, season, id) {
    return api.post(`/league/update/${id}`, {
      name,
      logo,
      flag,
      season
    });
  },
  updateOne(id, params) {
    return api.post(`/league/update/${id}`, params);
  },
  delete(id) {
    return api.delete(`/league/delete/${id}`);
  },
  leaguesByCountry(country) {
    return api.post('/league/all', { country });
  },
  updateLeagueData(actionName, id, params) {
    return api.get(`/league/action/${actionName}/${id}/${params}`);
  },
  getTeams(id) {
    return api.get(`/league/teams/${id}`);
  }
});
