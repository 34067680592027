import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const LeagueList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const titles = ['LİG ADI', 'LİG LOGOSU', 'BAYRAK'];

  const onDelete = async id => {
    api.league.delete(id).then(() => {
      navigate(0);
    });
  };

  const onAction = (id, type, name) => {
    if (type === 'isActive' || type === 'isPopular') {
      const rows = [...data];
      const getRowIndex = rows.findIndex(item => item._id === id);
      rows[getRowIndex][type] = !rows[getRowIndex][type];
      setData(rows);
      const params = {};
      params[type] = rows[getRowIndex][type];
      api.league.updateOne(id, params);
    }

    if (type === 'update') {
      navigate(`/league/update?id=${id}`);
    }

    if (type === 'actions') {
      navigate(`/league/actions?id=${id}&name=${name}`);
    }
  };

  useEffect(() => {
    api.league
      .list(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton className="mb-5 ml-auto" isFullWidth={false} onClick={() => navigate('/league/add')}>
        Yeni Lig Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        pageKey="league"
        pageNumber={page}
        onDelete={onDelete}
        actions={[
          {
            title: 'Düzenle',
            type: 'update'
          },
          {
            title: 'Güncelleme',
            type: 'actions'
          }
        ]}
        switchs={[
          {
            property: 'isActive',
            name: 'Yayında'
          },
          {
            property: 'isPopular',
            name: 'FAVORİ'
          }
        ]}
        onAction={onAction}
        hideRows="__v,_id,createdAt,updatedAt,leagueId,country,countryCode,isActive,isPopular,season"
      />
    </main>
  );
};

export default LeagueList;
