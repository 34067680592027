import { AppSelect, AppTable } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { t } from '../../locales';
import { useForm } from 'react-hook-form';

const TeamList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const titles = ['LİG', 'Takım Adı', 'Takım Logosu'];
  const [leagues, setLeagues] = useState([]);
  const [formData, setFormData] = useState({});

  const {
    control,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return formData;
    }, [formData])
  });

  useEffect(() => {
    api.league.list().then(res => {
      const leaguesData = [];
      res.data.map(data => {
        leaguesData.push({ value: data.leagueId, label: `${data.name} - ${data.country}` });
      });
      setLeagues(leaguesData);
      const leagueFormData = {
        league: leaguesData[0]
      };
      setFormData(leagueFormData);
      reset(leagueFormData);
    });
  }, []);

  const onDelete = async id => {
    api.team.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    const league = watch('league');
    if (!league) return;
    api.team
      .list(league.value)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, [watch('league')]);

  return (
    <main className="w-full">
      <div className="flex w-1/4 ml-auto flex-row items-center justify-center">
        <AppSelect
          options={leagues}
          label="Lig Seç"
          name="league"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.league')}
        />
      </div>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={false}
        pageKey="team"
        pageNumber={page}
        onDelete={onDelete}
        hideRows="__v,_id,createdAt,updatedAt,teamId,code,country,form,id,leagueId,code,country,founded,national,totalMatch,homeTotalMatch,awayTotalMatch,teamGoal,homeTeamGoal,awayTeamGoal,againstGoal,homeAgainstGoal,awayAgainstGoal,teamCornerKicks,homeTeamCornerKicks,awayTeamCornerKicks,againstCornerKicks,homeAgainstCornerKicks,awayAgainstCornerKicks,teamYellowCards,homeTeamYellowCards,awayTeamYellowCards,againstYellowCards,homeAgainstYellowCards,awayAgainstYellowCards,teamRedCards,homeTeamRedCards,awayTeamRedCards,againstRedCards,homeAgainstRedCards,awayAgainstRedCards,teamFirstHalfGoalMatches,homeTeamMutualGoalMatches,homeTeamFirstHalfGoalMatches,awayTeamFirstHalfGoalMatches,teamMutualGoalMatches,awayTeamMutualGoalMatches,teamTwoAndHalfGoalMatches,homeTeamTwoAndHalfGoalMatches,awayTeamTwoAndHalfGoalMatches"
      />
    </main>
  );
};

export default TeamList;
