import { apiConfig } from 'config';
import { authApi, userApi, teamApi, leagueApi, matchApi, countryApi, refereeApi, notificationApi } from 'services';

const api = apiConfig;

api.auth = authApi(api);
api.user = userApi(api);
api.team = teamApi(api);
api.league = leagueApi(api);
api.match = matchApi(api);
api.country = countryApi(api);
api.referee = refereeApi(api);
api.notification = notificationApi(api);

export default api;
