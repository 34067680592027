import React from 'react';

const AppIf = ({ condition, children }) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};

export default AppIf;
