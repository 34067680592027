import { Check, Close } from '@mui/icons-material';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { dateToString, timeToString } from 'utils/date';
import { AppIf, AppSwitch } from 'components';

const AppTable = ({
  titles,
  rows,
  isEdit = false,
  isDelete = false,
  onDelete,
  pageKey,
  pageNumber,
  hideRows = '',
  actions = [],
  onAction,
  switchs = []
}) => {
  const [, setSearchParams] = useSearchParams();
  const getValue = value => {
    if (value === null) {
      return '-';
    }

    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'string') {
      if (value.substring(0, 4) === 'http') {
        if (value.includes('youtube')) {
          return <a href={value}>{value}</a>;
        }
        return <img src={value} className="w-8 h-auto" />;
      }

      if (!isNaN(new Date(value).getDate()) && value.length > 15) {
        return `${dateToString(value)} ${timeToString(value)}`;
      }

      return value;
    }

    if (typeof value === 'boolean') {
      return value ? <Check /> : <Close />;
    }
    return value.name;
  };

  const checkValue = key => {
    const splitHiddenRows = hideRows.split(',');
    const getKey = typeof key === 'string' ? key : key.name;
    const findRow = splitHiddenRows.find(row => getKey === row);
    if (findRow) {
      return false;
    }

    return true;
  };

  const confirmDelete = id => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      onDelete(id);
    }
  };

  const setAction = (type, id, name) => {
    onAction(type, id, name);
  };

  return (
    <>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {rows && rows.length > 0 ? (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6">
                  #
                </th>
                {titles.map((title, index) => (
                  <th scope="col" className="py-3 px-6" key={`title${index}`}>
                    {title}
                  </th>
                ))}
                <AppIf condition={switchs && switchs.length > 0}>
                  {switchs.map((switchItem, index) => (
                    <th scope="col" key={index} className="py-3 px-6">
                      {switchItem.name}
                    </th>
                  ))}
                </AppIf>
                <AppIf condition={actions && actions.length > 0}>
                  <th scope="col" className="py-3 px-6">
                    İşlemler
                  </th>
                </AppIf>
                {isEdit && (
                  <th scope="col" className="py-3 px-6">
                    Düzenle
                  </th>
                )}
                {isDelete && (
                  <th scope="col" className="py-3 px-6">
                    Sil
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr className="bg-white border-b" key={`tr${index}`}>
                  <td className="px-6 py-4" key={`index${index}`}>
                    {(pageNumber - 1) * 10 + index + 1}
                  </td>
                  {Object.keys(row).map(
                    (key, index) =>
                      checkValue(key) && (
                        <td className="py-3 px-6 " key={`row${index}`}>
                          {getValue(row[key])}
                        </td>
                      )
                  )}
                  <AppIf condition={switchs && switchs.length > 0}>
                    {switchs.map((switchItem, index) => (
                      <td className="py-3 px-6" key={index}>
                        <AppSwitch
                          value={row[switchItem.property]}
                          onChange={() => setAction(row._id, switchItem.property, row.name)}
                        />
                      </td>
                    ))}
                  </AppIf>
                  <AppIf condition={actions && actions.length > 0}>
                    <td className="py-3 px-6">
                      {actions.map((action, index) => (
                        <button
                          key={index}
                          onClick={() => setAction(row._id, action.type, row.name)}
                          type="button"
                          className="py-2 px-3 text-xs font-medium text-center text-white bg-gray-700 rounded hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 mr-2"
                        >
                          {action.title}
                        </button>
                      ))}
                    </td>
                  </AppIf>
                  {isEdit && (
                    <td className="py-3 px-6">
                      <Link
                        to={`/${pageKey}/add?id=${row._id}`}
                        className="py-2 px-3 text-xs font-medium bg-blue-600 hover:bg-blue-800 cursor-pointer text-white rounded"
                      >
                        Düzenle
                      </Link>
                    </td>
                  )}
                  {isDelete && (
                    <td className="py-3 px-6">
                      <button
                        onClick={() => confirmDelete(row._id)}
                        className="py-2 px-3 text-xs font-medium bg-red-700 hover:bg-red-800 text-white rounded"
                      >
                        SİL
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center px-10 py-10 text-xl text-gray-500">Herhangi bir data bulunamadı.</div>
        )}
      </div>
      <div className="flex align-middle mt-4 justify-between">
        {pageNumber > 1 ? (
          <button
            // @ts-ignore
            onClick={() => setSearchParams({ page: pageNumber - 1 })}
            className="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Geri
          </button>
        ) : (
          <div></div>
        )}
        {rows && rows.length === 10 ? (
          <button
            onClick={() => setSearchParams({ page: pageNumber + 1 })}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            İleri
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default AppTable;
