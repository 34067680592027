import React, { useEffect, useMemo } from 'react';
import { AppButton, AppFile, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [flag, setFlag] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    if (isEdit) {
      api.league.get(id).then(async res => {
        const data = res.data;
        const editData = {
          name: data.name,
          season: data.season
        };
        setData(editData);
        reset(editData);
        setLogo(data.logo);
        setFlag(data.flag);
      });
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.league
      .update(submitData.name, logo, flag, submitData.season, id)
      .then(async () => {
        navigate('/league/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">Ligi Düzenle</div>
      <div className="text-lg text-gray-700 mb-8 text-center">Bu alanda ligi düzenleyebilirsiniz.</div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_leagues.name')}
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_leagues.name')}
        />
        <img src={logo} className="w-16 h-auto mt-5" />
        <AppFile error="" label="Lig Logosu" onCompleted={file => setLogo(file.cdnUrl)} crop="auto" />
        <img src={flag} className="w-16 h-auto mt-5" />
        <AppFile error="" label="Ülke Bayrağı" onCompleted={file => setFlag(file.cdnUrl)} crop="auto" />
        <AppInput
          label="Sezon"
          name="season"
          // @ts-ignore
          error={errors.season}
          rules={{ required: true }}
          register={register}
          placeholder="Sezon"
          maxLength={4}
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
