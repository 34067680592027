import { EmojiEvents, GroupsOutlined, HomeOutlined, Notifications, SportsSoccer, Sports } from '@mui/icons-material';

export default [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/',
    mainPath: '/',
    isVisible: true
  },
  {
    name: 'Maçlar',
    Icon: SportsSoccer,
    mainPath: '/match/list',
    path: '/match/list',
    isVisible: true
  },
  {
    name: 'Maç Ekle',
    Icon: SportsSoccer,
    path: '/match/add',
    mainPath: '/match/list',
    isVisible: false
  },
  {
    name: 'Takımlar',
    Icon: GroupsOutlined,
    path: '/team/list',
    mainPath: '/team/list',
    isVisible: true
  },
  {
    name: 'Takım Ekle',
    Icon: GroupsOutlined,
    path: '/team/add',
    mainPath: '/team/list',
    isVisible: false
  },
  {
    name: 'Hakemler',
    Icon: Sports,
    mainPath: '/referee/list',
    path: '/referee/list',
    isVisible: true
  },
  {
    name: 'Maç Ekle',
    Icon: Sports,
    path: '/referee/add',
    mainPath: '/referee/list',
    isVisible: false
  },
  {
    name: 'Ligler',
    Icon: EmojiEvents,
    path: '/league/list',
    mainPath: '/league/list',
    isVisible: true
  },
  {
    name: 'Lig Ekle',
    Icon: EmojiEvents,
    path: '/league/add',
    mainPath: '/league/list',
    isVisible: false
  },
  {
    name: 'Lig Düzenle',
    Icon: EmojiEvents,
    path: '/league/update',
    mainPath: '/league/list',
    isVisible: false
  },
  {
    name: 'Lig Güncelleme',
    Icon: EmojiEvents,
    path: '/league/actions',
    mainPath: '/league/list',
    isVisible: false
  },
  {
    name: 'Bildirimler',
    Icon: Notifications,
    path: '/notification/list',
    mainPath: '/notification/list',
    isVisible: true
  },
  {
    name: 'Bildirim Gönder',
    Icon: Notifications,
    path: '/notification/send',
    mainPath: '/notification/list',
    isVisible: false
  }
];
